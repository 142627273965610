import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import PostCard from "components/PostCard/PostCard"
import SEO from "../components/seo"
import { TagPostsWrapper, TagPageHeading, TagName } from "./templates.style"

const Tags = ({ pageContext, data }: any) => {
  const { tag } = pageContext
  console.log('data', data);

  const { edges, pageInfo: { itemCount } } = data.allContentfulPost

  return (
    <Layout>
      <SEO title={tag} description={`A collection of ${itemCount} post${itemCount > 1 ? 's' : ''}`} />

      <TagPostsWrapper>
        <TagPageHeading>
          <TagName>{tag}</TagName>
          {`A collection of ${itemCount} post${itemCount > 1 ? 's' : ''}`}
        </TagPageHeading>
        {edges.map(({ node }: any) => (
          <PostCard
            key={node.slug}
            title={node.title}
            image={
              node.cover == null
                ? null
                : node.cover.fluid
            }
            url={node.slug}
            description={node.summary.summary}
            date={node.updatedAt}
            tags={node.tags}
          />

        ))}
      </TagPostsWrapper>
    </Layout>
  )
}

export default Tags

export const pageQuery = graphql`
  query BlogPostByTag($tag: [String!]) {
    allContentfulPost(sort: {fields: updatedAt, order: DESC}, limit: 1000, filter: {tags: {in: $tag}}) {
      edges {
        node {
          title
          slug
          summary {
            summary
          }
          tags
          cover {
            fluid(maxWidth: 370, maxHeight: 220, quality: 90) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        } 
      }
      pageInfo {
        itemCount
      }
    }
  }
`
